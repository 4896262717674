.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.document {
}

.reactPdf {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reactPdf > div {
  max-width: calc("100% - 2em");
}

.reactPdf > div > canvas {
  max-width: 100%;
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.75em;
}

@media only screen and (max-width: 992px) {
  .reactPdf > div > canvas {
    width: 100vw !important;
  }
}

.reactPdf > div > div {
  height: 0px !important;
  width: 0px !important;
}
